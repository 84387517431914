@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};


.form-global{
  background-color: #f9f9f9;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
}

.nb-tree-grid{
  width: 200% !important;
  max-width: 200% !important;

}

.nb-tree-grid th {
  width: fit-content;
  white-space: nowrap;
}


nb-window{
  nb-card{
    background-color: rgba(255,255,255,0.9);
    margin-top: 6vw;
    box-shadow: 9px 9px 5px -2px rgba(0,30,120,0.74);
    -webkit-box-shadow: 9px 9px 5px -2px rgba(0,30,120,0.74);
    -moz-box-shadow: 9px 9px 5px -2px rgba(0,30,120,0.74);
    max-width: 100%;
    nb-card-header{
      background-image: linear-gradient(to right, #598bff, #3366ff);
      div{
        font-size: 3vh;
        font-weight: bold;
        color:#ffffff;
      }
    }
  }
}

.container-modal{
  background-color: #fff;
}

